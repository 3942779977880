import React from 'react';
import styled from '@emotion/styled';
import { CloudinaryImage } from '../CloudinaryImage';
import { ImageWithSize, Section } from '../../types';
import { ImageBox } from '../ImageBox';
import { aboveMobile } from '../../services/mediaquery';

const Root = styled.div`
    margin-top: 34px;
    text-align: center;
`;

const SectionAnchor = styled.a`
    cursor: pointer;
    text-align: center;
    display: inline-block;
    margin: 20px;
    &:hover {
        color: #a0a09f;
    }
    ${aboveMobile} {
        margin-top: 40px;
        margin-bottom: 40px;
    }
`;

const SectionName = styled.div`
    margin-top: 12px;
    font-family: 'Amatic SC', cursive;
    font-size: 2.1875rem;
    font-weight: 700;
    transition: color 200ms linear;
`;

type SectionsProps = {
    items: Section<ImageWithSize>[];
};

function Sections(props: SectionsProps) {
    const { items } = props;

    return (
        <Root>
            {items.map((section) => {
                return (
                    <SectionAnchor href={section.route} key={section.route}>
                        <ImageBox>
                            <CloudinaryImage
                                image={section.image.medium}
                                options={{
                                    fixedHeight: 280,
                                    fixedWidth: 280,
                                    quality: 90,
                                    crop: 'fill',
                                }}
                            />
                        </ImageBox>
                        <SectionName>{section.name}</SectionName>
                    </SectionAnchor>
                );
            })}
        </Root>
    );
}

export { Sections };
