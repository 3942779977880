import React from 'react';
import styled from '@emotion/styled';
import { CloudinaryOptions } from '../../services/cloudinary';
import {
    aboveMobile,
    belowEqualMobile,
    isMobileWidth,
} from '../../services/mediaquery';
import { ResponsiveCloudinaryImage } from '../ResponsiveCloudinaryImage';
import { ImageWithSize, Size } from '../../types';

const DESKTOP_HEADER_HEIGHT = 138;
const MOBILE_HEADER_HEIGHT = 185;

const Root = styled(ResponsiveCloudinaryImage)`
    ${aboveMobile} {
        position: sticky;
        top: ${DESKTOP_HEADER_HEIGHT}px;
    }
`;

interface FeaturedImageProps {
    featuredImage: {
        original: ImageWithSize;
        large: ImageWithSize;
        medium: ImageWithSize;
    };
}

function FeaturedImage(props: FeaturedImageProps) {
    const { featuredImage } = props;

    function getOptions(size: Size | undefined): CloudinaryOptions | undefined {
        function getFixedHeight() {
            if (!size) {
                return undefined;
            }

            const headerHeight = isMobileWidth(size.width)
                ? MOBILE_HEADER_HEIGHT
                : DESKTOP_HEADER_HEIGHT;

            const heightTreshold = isMobileWidth(size.width) ? 0.5 : 0.8;

            return (size.height - headerHeight) * heightTreshold;
        }

        function getFixedWidth() {
            if (!size) {
                return undefined;
            }
            return size.width;
        }

        const fixedHeight = getFixedHeight();
        const fixedWidth = getFixedWidth();

        if (fixedHeight === undefined || fixedWidth === undefined) {
            return undefined;
        }

        return {
            fixedHeight,
            fixedWidth,
            quality: 90,
            crop: 'fill',
        };
    }

    return <Root image={featuredImage.original} getOptions={getOptions} />;
}

export default FeaturedImage;
