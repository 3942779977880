import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import styled from '@emotion/styled';
import { CloudinaryImage } from '../CloudinaryImage';
import { ImageWithSize, Introduction } from '../../types';
import { ImageBox } from '../ImageBox';
import { aboveMobile } from '../../services/mediaquery';

const Root = styled.div`
    position: relative;
    display: inline-block;
    ${aboveMobile} {
        padding-bottom: 50px;
    }
`;

const Content = styled.div`
    font-size: 1.0625rem;
    line-height: normal;
    padding: 40px 18px 40px 55px;
    box-shadow: 3px 3px 20px 0px #aeaeae;
    background-color: #ffffff;
    ${aboveMobile} {
        width: 640px;
    }
`;

// const LinkBox = styled.div`
//     font-size: 1.1875rem;
//     font-weight: 700;
//     text-align: left;
//     margin-top: 15px;
//     > a {
//         transition: color 200ms linear;
//         &:hover {
//             color: #a0a09f;
//         }
//     }
// `;

const IntroImageBox = styled(ImageBox)`
    position: absolute;
    top: -110px;
    left: 25px;
    ${aboveMobile} {
        top: 25px;
        left: 25px;
        transform: translateX(-100%);
    }
`;

const Image = styled(CloudinaryImage)`
    width: 160px;
    height: 160px;
    background-position: -30px 0;
    ${aboveMobile} {
        width: 400px;
        height: 368px;
        background-position: -70px 0;
    }
`;

type IntroProps = Introduction<ImageWithSize>;

function Intro(props: IntroProps) {
    const { content, image } = props;

    return (
        <Root>
            <Content>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {content}
                </ReactMarkdown>
                {/* <LinkBox>
                    <Link to={link.route}>{link.text}</Link>
                </LinkBox> */}
            </Content>
            <IntroImageBox>
                <Image
                    image={image.large}
                    options={{
                        fixedHeight: 367,
                        fixedWidth: 500,
                        quality: 90,
                        crop: 'fill',
                    }}
                />
            </IntroImageBox>
        </Root>
    );
}

export { Intro };
