import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Container } from '../components/Layout/Container';
import { HomePageData, ImageWithSize } from '../types';
import { Intro } from '../components/HomePage/Intro';
import { Sections } from '../components/HomePage/Sections';
import { aboveMobile } from '../services/mediaquery';
import FeaturedImage from '../components/HomePage/FeaturedImage';

const Root = styled.div``;

const Wrapper = styled.div`
    position: relative;
    z-index: 200px;
    background-color: #ffffff;
    padding-top: 120px;
    ${aboveMobile} {
        padding-top: 40px;
        padding-bottom: 40px;
    }
`;

interface HomePageProps {
    data: {
        site: {
            siteMetadata: {
                homePage: HomePageData<ImageWithSize>;
            };
        };
    };
}

function HomePage(props: HomePageProps) {
    const {
        data: {
            site: {
                siteMetadata: { homePage },
            },
        },
    } = props;

    return (
        <Root>
            <FeaturedImage featuredImage={homePage.featuredImage} />
            <Wrapper>
                <Container>
                    <Intro
                        content={homePage.introduction.content}
                        image={homePage.introduction.image}
                    />

                    <Sections items={homePage.sections} />
                </Container>
            </Wrapper>
        </Root>
    );
}

export const query = graphql`
    query QueryHomePage {
        site {
            siteMetadata {
                homePage {
                    sections {
                        route
                        name
                        image {
                            original {
                                url
                                width
                                height
                            }
                            medium {
                                url
                                width
                                height
                            }
                            large {
                                height
                                url
                                width
                            }
                        }
                    }
                    featuredImage {
                        original {
                            url
                            width
                            height
                        }
                        medium {
                            url
                            width
                            height
                        }
                        large {
                            height
                            url
                            width
                        }
                    }
                    introduction {
                        content
                        image {
                            original {
                                url
                                width
                                height
                            }
                            medium {
                                url
                                width
                                height
                            }
                            large {
                                height
                                url
                                width
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default HomePage;
